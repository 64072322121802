<template>
	<div class="animated fadeIn">
		<div
			v-if="!loading"
			class="row mb-2 d-flex text-right">
			<div class="col-md-6 col-lg-3">
				<icon-card-widget
					:big-label="totals.period_refunds"
					:small-label="translate('period_refunds')" />
			</div>
		</div>

		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('user_id')">
							{{ translate('user_id') }}
							<sort field="user_id" />
						</th>
						<th
							class="border-top-0 pointer"
							style="max-width: 100px"
							@click="sortByField('order_id')">
							{{ translate('order_id') }}
							<sort field="order_id" />
						</th>
						<th
							class="border-top-0 pointer"
							style="max-width: 100px"
							@click="sortByField('invoice_id')">
							{{ translate('invoice_id') }}
							<sort field="invoice_id" />
						</th>
						<th
							class="border-top-0 pointer text-right"
							@click="sortByField('base_subtotal')">
							{{ translate('subtotal') }}
							<sort field="base_subtotal" />
						</th>
						<th
							class="border-top-0 pointer text-right"
							@click="sortByField('base_shipping')">
							{{ translate('shipping') }}
							<sort field="base_shipping" />
						</th>
						<th
							class="border-top-0 pointer text-right"
							@click="sortByField('base_tax')">
							{{ translate('duties') }}
							<sort field="base_tax" />
						</th>
						<th
							class="border-top-0 pointer text-right"
							@click="sortByField('base_total')">
							{{ translate('total') }}
							<sort field="base_total" />
						</th>
						<th
							class="border-top-0 pointer text-right"
							@click="sortByField('base_refunded')">
							{{ translate('refund_amount') }}
							<sort field="base_refunded" />
						</th>
						<th
							class="border-top-0 pointer text-left"
							@click="sortByField('code_name')">
							{{ translate('reason') }}
							<sort field="code_name" />
						</th>
						<th
							class="border-top-0 pointer text-left"
							@click="sortByField('notes')">
							{{ translate('notes') }}
							<sort field="notes" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('date_added')">
							{{ translate('order_date') }}
							<sort field="date_added" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('date_refunded')">
							{{ translate('date_refunded') }}
							<sort field="date_refunded" />
						</th>
						<th
							class="border-top-0">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ `${(((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1))}` }}
						</td>
						<td class="text-left">
							<span
								v-if="admin.includes($user.details().type) && item.attributes.user_type === distributor"
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
								{{ item.attributes.user_id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.attributes.user_id }}
							</span>
						</td>
						<td class="text-left">
							{{ item.id }}
							<i
								v-if="item.attributes.is_fraud"
								v-b-tooltip.hover
								:title="translate('fraudulent_order')"
								class="fa fa-info-circle"
								style="margin-left:5px; margin-top:2px; color:#f86c6b" />
							<i
								v-if="item.attributes.is_invisible"
								v-b-tooltip.hover
								:title="translate('invisible_order')"
								class="fa fa-eye-slash"
								style="margin-left:5px; margin-top:2px; color:gray" />
						</td>
						<td class="text-left">
							{{ item.attributes.invoice_id }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_subtotal }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_shipping }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_tax }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_total }}
						</td>
						<td class="text-right font-weight-bold">
							{{ item.attributes.base_refunded }}
						</td>
						<td class="text-left">
							{{ item.attributes.reason_code_name ? translate(item.attributes.reason_code_name) : '' }}
						</td>
						<td class="text-left">
							{{ item.attributes.notes ? item.attributes.notes : '' }}
						</td>
						<td
							class="text-left"
							style="width: 120px">
							{{ item.attributes.date_added ? $moment(item.attributes.date_added).format(dateFormat) : item.attributes.date_added }}
						</td>
						<td
							class="text-left"
							style="width: 120px">
							{{ item.attributes.date_refunded ? $moment(item.attributes.date_refunded).format(dateFormat) : item.attributes.date_refunded }}
						</td>
						<td class="align-middle">
							<b-button
								v-if="item.attributes.order_refund_id != null"
								v-b-tooltip.hover
								:title="translate('download')"
								:disabled="loading"
								class="btn mx-1 bg-primary-alt"
								@click="download(item.attributes.order_refund_id, item.id)">
								<i
									class="fa fa-download"
									aria-hidden="true" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import { Report, Grids, OrdersDetails } from '@/translations';
import {
	YMD_FORMAT as ymdFormat,
	MDY_FORMAT as mdyFormat,
} from '@/settings/Dates';
import { admin, distributor } from '@/settings/Roles';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import FiltersParams from '@/mixins/FiltersParams';
import Refunds from '@/util/Report';
import Orders from '@/util/Order';
import IconCardWidget from '@/components/IconCardWidget';

export default {
	name: 'RefundsAll',
	messages: [Report, Grids, OrdersDetails],
	components: { IconCardWidget },
	mixins: [FiltersParams, DashboardRedirect],
	data() {
		return {
			refundsAll: new Refunds(),
			downloadRefund: new Orders(),
			dateFormat: ymdFormat,
			admin,
			distributor,
			alert: new this.$Alert(),
		};
	},
	computed: {
		loading() {
			return !!this.refundsAll.data.loading;
		},
		errors() {
			return this.refundsAll.data.errors;
		},
		pagination() {
			return this.refundsAll.data.pagination;
		},
		data() {
			try {
				const { data } = this.refundsAll.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		totals() {
			try {
				const { totals } = this.refundsAll.data.response.data.meta;
				return totals;
			} catch (error) {
				return {};
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
	mounted() {
		this.$parent.rangeStart = '';
		this.$parent.rangeEnd = '';
		this.refundsAll.getAllRefunds().then(() => {
			const { start, end } = this.refundsAll.data.response.data.meta.period_dates;
			this.$parent.rangeStart = this.$moment(start.date).format(mdyFormat);
			this.$parent.rangeEnd = this.$moment(end.date).format(mdyFormat);
		});
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.refundsAll.getAllRefunds(options);
		},
		download(refundId, orderId) {
			const config = {
				allowEscapeKey: false,
				allowOutsideClick: false,
			};

			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), config);
			this.downloadRefund.downloadRefund(refundId).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]), { type: response.headers['content-type'] });
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.translate('refund_order_file')} ${orderId}.pdf`);
				this.$el.appendChild(link);
				link.click();
				this.alert.close();
			}).catch(() => {
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
	},
};
</script>
